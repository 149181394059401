import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const PublicRoutesPermission = lazy(() => import('components/PublicRoutesPermission'));
const TwoFa = lazy(() => import('../views/TwoFa'));

export default [
    {
        path: '/auth/two-fa',
        element: (
            <PublicRoutesPermission>
                <TwoFa />
            </PublicRoutesPermission>
        )
    }
] as RouteObject[];
