import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const PublicRoutesPermission = lazy(() => import('components/PublicRoutesPermission'));
const Login = lazy(() => import('../views/Login'));

export default [
    {
        path: '/auth/login',
        element: (
            <PublicRoutesPermission>
                <Login />
            </PublicRoutesPermission>
        )
    }
] as RouteObject[];
