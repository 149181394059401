import type {RouteObject} from 'react-router-dom';

import ProtectedRoutesLayout from '../components/RoutesLayout/ProtectedRoutesLayout';
import BusinessAccountsRoutes from 'features/BusinessAccountUsers/routes';
import BackupCodesRoutes from 'features/BackupCodes/routes';
import DashboardRoutes from 'features/Dashboard/routes';
import ContractsRoutes from 'features/Contracts/routes';
import BillsRoutes from 'features/Bills/routes';
import MediaRoutes from 'features/Media/routes';
import ForbiddenRoutes from '../features/Forbidden/routes';

export default [
    {
        path: '',
        element: <ProtectedRoutesLayout />,
        children: [
            ...BusinessAccountsRoutes,
            ...BackupCodesRoutes,
            ...DashboardRoutes,
            ...ContractsRoutes,
            ...BillsRoutes,
            ...MediaRoutes,
            ...ForbiddenRoutes
        ]
    }
] as RouteObject[];
