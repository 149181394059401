import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const Dashboard = lazy(() => import('../views/Dashboard'));

export default [
    {
        path: 'dashboard',
        element: (
            <RequireAuthAndPermission requiredPermission="can_view_dashboard">
                <Dashboard />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
