const links = [
    {
        to: '/dashboard',
        label: 'dashboard',
        permission: 'can_view_dashboard'
    },
    {
        to: '/business-account-users',
        label: 'users',
        permission: 'business_owner'
    },
    {
        to: '/contracts',
        label: 'contracts',
        permission: 'can_view_contracts'
    },
    {
        to: '/bills',
        label: 'bills',
        permission: 'can_view_bills'
    },
    {
        to: '/media',
        label: 'media',
        permission: 'can_view_dashboard'
    }
];

export {links};
