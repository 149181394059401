import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import {immer} from 'zustand/middleware/immer';
import produce from 'immer';
import {devtools, persist} from 'zustand/middleware';

import {STORE_PERSIST_RELATIVE_PATH_KEY} from 'config';
import axiosErrorHandler from 'utils/axiosErrorHandler';
import api, {customAuthorizationApi} from 'lib/api';
import showCustomNotification from 'utils/showCustomNotification';
import type {UseLoginStoreType} from '../types';
import i18n from 'i18n';

const keysToPartialize = ['authUser', 'currentBusinessAccount', 'accessToken', 'refreshToken'];

export const twoFaStore = createVanilla<UseLoginStoreType>()(
    immer(
        devtools(
            persist(
                (set, get) => ({
                    authUser: null,
                    currentBusinessAccount: null,
                    accessToken: null,
                    refreshToken: null,
                    loading: false,
                    error: false,
                    errorMessage: null,
                    errorKey: null,
                    setCurrentBusinessAccount(business_account_id) {
                        if (!get().authUser?.businesses || !business_account_id) {
                            return Promise.resolve(null);
                        }
                        set({
                            currentBusinessAccount: get().authUser?.businesses?.find(
                                (businessAccount) =>
                                    businessAccount.business_account_id === business_account_id
                            )
                        });

                        return Promise.resolve('ok');
                    },
                    async fetchUserTokens() {
                        try {
                            const localStorageName = twoFaStore.persist.getOptions().name ?? '';
                            const localStorageData = twoFaStore.persist.getOptions().getStorage?.();
                            const myLocalStorage = (localStorageData as any)[localStorageName];
                            const myLocalStorageParsed = JSON.parse(myLocalStorage);
                            const refreshToken = myLocalStorageParsed?.state?.refreshToken;
                            const bearerToken = `Bearer ${refreshToken}`;

                            const {data: tokens} = await customAuthorizationApi(bearerToken).get(
                                `v1/dashboard-auth/login/refresh-tokens`
                            );
                            set({
                                accessToken: tokens.access_token,
                                refreshToken: tokens.refresh_token
                            });
                            return [tokens, tokens.status_changed, null];
                        } catch (error) {
                            set(axiosErrorHandler(error));
                            return [null, null, axiosErrorHandler(error)];
                        }
                    },
                    async authenticateUser(code, token, useBackupCode = false) {
                        set({loading: true, error: false, errorMessage: null, errorKey: null});
                        try {
                            const codeRoute = useBackupCode ? 'use-backup-code' : 'check-code';
                            const bearerToken = `Bearer ${token}`;
                            const {data: authUser} = await customAuthorizationApi(bearerToken).post(
                                `v1/dashboard-auth/login/${codeRoute}`,
                                code
                            );
                            set({
                                authUser,
                                currentBusinessAccount: authUser?.businesses[0] ?? null,
                                accessToken: authUser.access_token,
                                refreshToken: authUser.refresh_token
                            });
                            return [get().currentBusinessAccount, null];
                        } catch (error) {
                            set(axiosErrorHandler(error));
                            return [null, axiosErrorHandler(error)];
                        } finally {
                            set({loading: false});
                        }
                    },
                    async fetchUserBusinessInfo() {
                        set({loading: true, error: false, errorMessage: null, errorKey: null});
                        try {
                            const {data: userBusinessInfo} = await api.get(
                                'v1/web/business-accounts/user/dashboard-info'
                            );
                            set(
                                produce((state) => {
                                    state.authUser.businesses = userBusinessInfo;
                                })
                            );

                            const currentBusinessAccountToUpdate = get().authUser?.businesses?.find(
                                (businessAccount) =>
                                    businessAccount.business_account_id ===
                                    get().currentBusinessAccount?.business_account_id
                            );

                            if (Object.keys(currentBusinessAccountToUpdate ?? {}).length > 0) {
                                set({
                                    currentBusinessAccount: currentBusinessAccountToUpdate
                                });
                            } else {
                                showCustomNotification({
                                    type: 'error',
                                    message: i18n.t('businessAccountDeletedWithName', {
                                        name: get().currentBusinessAccount?.name
                                    }) as string
                                });
                                set({
                                    currentBusinessAccount: userBusinessInfo[0]
                                });
                            }

                            return [userBusinessInfo, null];
                        } catch (error) {
                            set(axiosErrorHandler(error));
                            return [null, axiosErrorHandler(error)];
                        } finally {
                            set({loading: false});
                        }
                    },
                    async logout() {
                        set({
                            authUser: null,
                            currentBusinessAccount: null,
                            accessToken: null,
                            refreshToken: null
                        });
                        twoFaStore.persist.clearStorage();
                    }
                }),
                {
                    name: `${STORE_PERSIST_RELATIVE_PATH_KEY}-dashboard-auth`,
                    version: 1.0,
                    partialize: (state) =>
                        Object.fromEntries(
                            Object.entries(state).filter(([key]) => keysToPartialize.includes(key))
                        )
                }
            )
        )
    )
);

export default create(twoFaStore);
