import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const Bills = lazy(() => import('../views/Bills'));

export default [
    {
        path: 'bills',
        element: (
            <RequireAuthAndPermission requiredPermission="can_view_bills">
                <Bills />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
