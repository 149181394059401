import {memo, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import PageLoader from '../PageLoader';

const PublicRoutesLayout = () => {
    return (
        // <Suspense fallback={<PageLoader />}>
        <Outlet />
        // </Suspense>
    );
};

export default memo(PublicRoutesLayout);
