import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const NotFound = lazy(() => import('../views/NotFound'));

export default [
    {
        path: '*',
        element: <NotFound />
    }
] as RouteObject[];
