import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const Media = lazy(() => import('../views/Media'));
const MediaForm = lazy(() => import('../views/MediaForm'));

export default [
    {
        path: 'media',
        element: (
            <RequireAuthAndPermission requiredPermission="can_view_dashboard">
                <Media />
            </RequireAuthAndPermission>
        )
    },
    {
        path: 'media/:id',
        element: (
            <RequireAuthAndPermission requiredPermission="can_view_dashboard">
                <MediaForm />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
