import {showNotification} from '@mantine/notifications';
import {NotificationProps} from '@mantine/notifications/lib/types';

import i18n from '../i18n';

interface ShowCustomNotificationPropsType extends NotificationProps {
    type: 'error' | 'success';
}

const notificationTypes = {
    error: {
        title: i18n.t('errorNotificationTitle') as string,
        color: 'red'
    },
    success: {
        title: i18n.t('successNotificationTitle') as string,
        color: 'HR_BLUE'
    }
};

const showCustomNotification = (props: ShowCustomNotificationPropsType): void => {
    const {message = '', type, ...restProps} = props;

    showNotification({
        message,
        ...notificationTypes[type as 'error' | 'success'],
        ...restProps
    });
};

export default showCustomNotification;
