import {BrandInstagram, World, BrandFacebook, BrandLinkedin} from 'tabler-icons-react';

const links = [
    {
        component: (
            <World
                size={24}
                strokeWidth={1.5}
            />
        ),
        url: 'https://horserepublic.com/'
    },
    {
        component: (
            <BrandInstagram
                size={24}
                strokeWidth={1.5}
            />
        ),
        url: 'https://www.instagram.com/horserepublic/'
    },
    {
        component: (
            <BrandFacebook
                size={24}
                strokeWidth={1.5}
            />
        ),
        url: 'https://www.facebook.com/HorseRepublicOfficiel'
    },
    {
        component: (
            <BrandLinkedin
                size={24}
                strokeWidth={1.5}
            />
        ),
        url: 'https://www.linkedin.com/company/horse-republic'
    }
];

export {links};
