import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import en from './translations/en';
import fr from './translations/fr';

const resources = {
    en: {
        translation: {
            ...en
        }
    },
    fr: {
        translation: {
            ...fr
        }
    }
};

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v4',
        resources,
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false
        },
        backend: {
            backends: [LocalStorageBackend]
        }
    });

export default i18n;
