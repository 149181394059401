import {AxiosError} from 'axios';
import {AxiosErrorType, BackendErrorType} from 'types/AxiosType';

export default function AxiosErrorHandler(error: AxiosError<BackendErrorType>): AxiosErrorType {
    let errorKey, errorMessage, errorStatus;

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.warn('AXIOS error response: ', error.response);

        errorKey = error.response?.data?.error;
        errorMessage = error.response?.data?.message;
        errorStatus = error.response?.status;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.warn('AXIOS error request: ', error.request);

        errorKey = 'ERR_REQUEST';
        errorMessage =
            'Your internet connection is bad or our servers are currently unavailable. Please try again later.';
        errorStatus = null;
    } else {
        // Something happened in setting up the request that triggered an Error
        console.warn('AXIOS error: ', error.message);

        errorKey = 'ERR_SETTING_UP_REQUEST';
        errorMessage = 'Something went wrong...';
        errorStatus = null;
    }

    return {
        error: true,
        errorKey,
        errorMessage,
        errorStatus
    };
}
