import {createStyles} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: 120,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column'
        }
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md
        }
    },

    companyInfo: {
        [theme.fn.smallerThan('xs')]: {
            textAlign: 'center',
            justifyContent: 'center'
        }
    },

    companyInfoText: {
        maxWidth: '550px',
        marginLeft: theme.spacing.lg,
        [theme.fn.smallerThan('xs')]: {
            marginLeft: 0
        }
    }
}));

export default useStyles;
