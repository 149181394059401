import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const BackupCodes = lazy(() => import('../views/BackupCodes'));

export default [
    {
        path: 'backup-codes',
        element: (
            <RequireAuthAndPermission requiredPermission="admin">
                <BackupCodes />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
