import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const Forbidden = lazy(() => import('../views/Forbidden'));

export default [
    {
        path: '403',
        element: <Forbidden />
    }
] as RouteObject[];
