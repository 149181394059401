import {memo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Menu, Group, Avatar, ActionIcon, useMantineColorScheme, Divider} from '@mantine/core';
import {Logout, Code, Sun, MoonStars, CircleDotted} from 'tabler-icons-react';

import useStyles from './UserAvatarStyles';
import useTwoFaStore from 'features/TwoFa/store/useTwoFaStore';
import usePermissions from 'hooks/usePermissions';

const UserAvatar = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();
    const {authUser, logout, setCurrentBusinessAccount, currentBusinessAccount} = useTwoFaStore();
    const {classes} = useStyles();
    const {permissionToNavigate} = usePermissions();

    const dark = colorScheme === 'dark';

    const businessAccountMenuItems = authUser?.businesses?.map((businessAccount) => {
        return (
            <Menu.Item
                icon={<CircleDotted size={18} />}
                key={businessAccount.business_account_id}
                onClick={async () => {
                    setCurrentBusinessAccount(businessAccount.business_account_id).then(
                        (ok: string | null) => {
                            if (!ok) return;

                            navigate(
                                permissionToNavigate({
                                    permissions: businessAccount?.permissions ?? []
                                })
                            );
                            window.location.reload();
                        }
                    );
                }}
                color={
                    currentBusinessAccount?.business_account_id ===
                    businessAccount.business_account_id
                        ? 'HR_BLUE'
                        : ''
                }
            >
                {businessAccount.name}
            </Menu.Item>
        );
    });

    const changeThemeMenuItem = (
        <Menu.Item
            onClick={() => toggleColorScheme()}
            color={dark ? 'yellow' : 'blue'}
            icon={
                dark ? (
                    <Sun
                        color="yellow"
                        size={18}
                    />
                ) : (
                    <MoonStars
                        color="blue"
                        size={18}
                    />
                )
            }
        >
            {dark ? t('lightTheme') : t('darkTheme')}
        </Menu.Item>
    );

    return (
        <Group position="center">
            <Menu
                withArrow
                position="bottom-end"
                width={300}
                transition="pop"
                offset={15}
                classNames={{
                    dropdown: classes.dropdown
                }}
            >
                <Menu.Target>
                    <ActionIcon>
                        <Avatar />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        icon={<Code size={18} />}
                        component={Link}
                        to="/backup-codes"
                    >
                        {t('generateBackupCodes')}
                    </Menu.Item>
                    {changeThemeMenuItem}
                    <Menu.Item
                        onClick={logout}
                        icon={<Logout size={18} />}
                    >
                        {t('logout')}
                    </Menu.Item>
                    <Divider
                        size="sm"
                        my="xs"
                    />
                    <Menu.Label>{t('myAccounts')}</Menu.Label>
                    {businessAccountMenuItems}
                </Menu.Dropdown>
            </Menu>
        </Group>
    );
};

export default memo(UserAvatar);
