import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const PublicRoutesPermission = lazy(() => import('components/PublicRoutesPermission'));
const Landing = lazy(() => import('../views/Landing'));

export default [
    {
        path: '/',
        element: (
            <PublicRoutesPermission>
                <Landing />
            </PublicRoutesPermission>
        )
    }
] as RouteObject[];
