import {OptionType} from 'dayjs';

export const HR_PINK = '#ffc1cd';
export const HR_RED = '#e31e55';
export const HR_BLUE = '#01aefd';
export const HR_TEAL = '#005e8a';
export const HR_BROWN = '#b09f9e';
export const HR_YELLOW = '#f4ea68';
export const HR_GRAY = '#E4E4E4';
export const HR_GREEN = '#75cbbb';
export const HR_WHITE = '#ffffff';
export const HR_DARK_GRAY = '#606266';
export const HR_BLACK = '#000000';

export type ColorsList =
    | 'HR_PINK'
    | 'HR_RED'
    | 'HR_BLUE'
    | 'HR_BROWN'
    | 'HR_YELLOW'
    | 'HR_GRAY'
    | 'HR_DARK_GRAY'
    | 'HR_GREEN'
    | 'HR_TEAL'
    | 'HR_WHITE'
    | 'HR_BLACK';

const colors: Record<ColorsList, string> = {
    HR_PINK,
    HR_RED,
    HR_BLUE,
    HR_BROWN,
    HR_YELLOW,
    HR_GRAY,
    HR_DARK_GRAY,
    HR_GREEN,
    HR_WHITE,
    HR_BLACK,
    HR_TEAL
};

export const colorsShades: Record<
    ColorsList,
    [string, string, string, string, string, string, string, string, string, string]
> = {
    HR_BLACK: ['', '', '', '', '', '', '', '', '', ''],
    HR_BROWN: ['', '', '', '', '', '', '', '', '', ''],
    HR_DARK_GRAY: ['', '', '', '', '', '', '', '', '', ''],
    HR_GRAY: ['', '', '', '', '', '', '', '', '', ''],
    HR_GREEN: ['', '', '', '', '', '', '', '', '', ''],
    HR_TEAL: ['', '', '', '', '', '', '', '', '', ''],
    HR_WHITE: ['', '', '', '', '', '', '', '', '', ''],
    HR_YELLOW: ['', '', '', '', '', '', '', '', '', ''],
    HR_BLUE: [
        '#D9E4E9',
        '#BCD3DD',
        '#9EC4D6',
        '#7DB9D5',
        '#59B2DA',
        '#30AEE7',
        '#01AEFD',
        '#1894CD',
        '#267FA8',
        '#335463'
    ],
    HR_RED: [
        '#E9DEE1',
        '#DBC3CA',
        '#D1A7B3',
        '#CD8A9C',
        '#CD6A86',
        '#D4476E',
        '#E31E55',
        '#BC2C54',
        '#9C3451',
        '#6F3948'
    ],
    HR_PINK: [
        '#FFC1CD',
        '#FFC1CD',
        '#FFC1CD',
        '#FFC1CD',
        '#FFC1CD',
        '#FFC1CD',
        '#FFC1CD',
        '#E58699',
        '#D27487',
        '#BF6778'
    ]
};

export const colorsKeys = Object.keys(colors) as ColorsList[];

export default colors;
