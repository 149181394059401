import type {RouteObject} from 'react-router-dom';

import PublicRoutesLayout from '../components/RoutesLayout/PublicRoutesLayout';
import LandingRoutes from 'features/Landing/routes';
import LoginRoutes from 'features/Login/routes';
import TwoFaRoutes from 'features/TwoFa/routes';
import EnableTwoFaRoutes from 'features/EnableTwoFa/routes';

export default [
    {
        path: '',
        element: <PublicRoutesLayout />,
        children: [...LandingRoutes, ...LoginRoutes, ...TwoFaRoutes, ...EnableTwoFaRoutes]
    }
] as RouteObject[];
