import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const Contracts = lazy(() => import('../views/Contracts'));

export default [
    {
        path: 'contracts',
        element: (
            <RequireAuthAndPermission requiredPermission="can_view_contracts">
                <Contracts />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
