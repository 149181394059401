// prettier-ignore
export default {
    landingDescription: 'Track the performance of your campaigns and activations in near real time. Optimize your investments. Take control of your digital marketing budget.',
    aFullyFeatured: 'Marketing transparency tool',
    for: 'for',
    yes: 'Yes',
    no: 'No',
    expired: 'Expired',
    countries: 'Countries',
    login: 'Login',
    logout: 'Logout',
    name: 'Name',
    lastName: 'Last Name',
    description: 'Description',
    invalidEmail: 'Invalid Email',
    email: 'Email',
    update: 'Update',
    lastUpdatedBy: 'Last Updated By',
    yourEmail: 'Your Email',
    required: 'Required',
    password: 'Password',
    yourPassword: 'Your Password',
    goBackToLogin: 'Go Back to Login',
    goBackToHomepage: 'Go Back to Homepage',
    welcomeBack: 'Welcome Back',
    enterCode: 'Enter Code',
    twoFactorAuth: 'Two Factor Authentication',
    twoFactorAuthInfo: 'You can use your two-factor authentication mobile app or a backup code',
    enterAuthenticationBackupCode: 'Enter auth / backup code',
    enterAuthenticationBackupCodePlaceholder: '6-digit authentication code / 8-digit backup code',
    enterAuthenticationCode: 'Enter Authentication Code',
    enterAuthenticationCodeInfo: 'Enter the code from the authenticator app on your mobile device',
    backupCodesInfo: 'Should you ever lose your phone or access to your one time password secret, each of these recovery codes can be used one time each to regain access to your account. Please save them in a safe place.',
    downloadBackupCodes: 'Download Backup Codes',
    generateBackupCodes: 'Generate Backup Codes',
    errorComponentTitle: 'Something is not right...',
    errorComponentDescription: 'Our servers could not handle your request for this specific data. Don\'t worry, our development team was already notified. Try refreshing the page in a few minutes.',
    comingSoon: 'Coming Soon',
    code: 'Code',
    copyCodes: 'Copy Codes',
    copiedCodes: 'Codes Copied',
    downloadCodes: 'Download Codes',
    proceed: 'Proceed',
    secretKeyInfo: 'If you are unable to scan the QR code, enter the secret key manually in your app',
    authenticationAppInfo: 'Use mobile authenticator app to scan the qr code',
    scanQrCode: 'Scan QR Code',
    error: 'Error',
    notAuthenticated: 'You are not authenticated',
    youAreNotAuthorized: 'You are not authorized to access this page',
    characterRequiredWithCount: '{{ num }} characters required',
    characterRequiredWithCountTwo: '{{ count1 }} or {{ count2 }} Characters Required',
    nextStep: 'Next Step',
    back: 'Back',
    cancel: 'Cancel',
    users: 'Users',
    edit: 'Edit',
    delete: 'Delete',
    search: 'Search',
    deleteUserInfo: 'Are you sure you want to delete this user ?',
    businessAccountUserDeleted: 'Business Account User Deleted',
    businessAccountUserUpdated: 'Business Account User Updated',
    businessAccountDeletedWithName: 'Business Account "{{ name }}" Deleted',
    clearFilters: 'Clear Filters',
    errorNotificationTitle: 'Error',
    successNotificationTitle: 'Success',
    accountActivatedSuccessfully: 'Your account has been activated successfully',
    forbiddenErrorInfo: 'Unfortunately, this is only a 403 page. You don\'t have permission to view this page.',
    takeMeBackToHomePage: 'Take me back to the home page',
    permissions: 'Permissions',
    people: 'people',
    audienceReachedOut: 'Your total audience reaches today',
    subscription: 'Subscription',
    noCampaignsInfo: 'No activation planned for the moment. It\'s coming soon, our team is working on it!',
    upcoming: 'Upcoming',
    media: 'Media',
    mediaSpace: 'The MEDIA Space',
    mediaSpaceInfo: 'Upload here all the documents, logos or images which will be needed for your activations.',
    mediaCreatedSuccessfully: 'Media created successfully',
    deleteMediaInfo: "Are you sure you want to delete this file?",
    mediaDeletedSuccessfully: 'Media deleted successfully',
    mediaUpdatedSuccessfully: 'Media updated successfully',
    fileTooLargeWithSize: 'File too large. Max size is {{ size }}',
    fileInvalidType: 'Invalid file type: jpg, jpeg, gif, webp and pdf are allowed',
    sendInvitation: 'Send Invitation',
    invitationSentSuccessfully: 'Invitation sent successfully',
    invite: 'Invite',
    or: 'or',
    yourExpert: 'Your Expert',
    weHaveAMessage: 'We have a message for you:',
    noMessage: 'No message',
    darkTheme: 'Dark Theme',
    lightTheme: 'Light Theme',
    campaigns: 'Campaigns',
    mailings: 'Mailings',
    articles: 'Articles',
    videos: 'Videos',
    social: 'Social',
    notifications: 'Notifications',
    survey: 'Survey',
    activations: 'Activations',
    planned_activations: 'Planned Activations',
    contractDuration: 'Contract Duration',
    daysRemainingWithCount_one: '{{ count }} day remaining',
    daysRemainingWithCount_other: '{{ count }} days remaining',
    daysPassedWithCount_one: '{{ count }} day passed',
    daysPassedWithCount_other: '{{ count }} days passed',
    contractStartsInWithCount_one: 'Contract starts in\n{{ count }} day',
    contractStartsInWithCount_other: 'Contract starts in\n{{ count }} days',
    contractExpiredWithCount_one: 'Contract expired\n{{ count }} day ago',
    contractExpiredWithCount_other: 'Contract expired\n{{ count }} days ago',
    noContractRunning: 'No contract running',
    toDo: 'to do',
    done: 'done',
    performance: 'Performance',
    startDate: 'Start Date',
    endDate: 'End Date',
    duration: 'Duration',
    published: 'Published',
    scope: 'Scope',
    views: 'Views',
    viewsWithCount_one: '{{ count }} view',
    viewsWithCount_other: '{{ count }} views',
    dragFiles: 'Drag file here or click to select file',
    dragFilesInfo: 'File should not exceed 5mb.',
    footerBusinessInfo: 'Business Dashboard est une solution de suivi marketing destinée aux abonnés Business Members ayant souscrit l\'option. Ce site et ses contenus sont la propriété exclusive de Horse Republic SAS. Tous droits réservés. Version Beta Novembre 2022',
    dashboard: 'Dashboard',
    uploadMedia: 'Upload Media',
    getInTouch: 'Get In Touch',
    myAccounts: 'My Accounts',
    contracts: 'Contracts',
    bills: 'Bills',
    create: 'Create',
    created: 'Created',
    sentAt: 'Sent At',
    totalClicks: 'Total Clicks',
    totalViews: 'Total Views',
    readings: 'Readings',
    crt: 'CRT',
    type: 'Type',
    message: 'Message',
    statsView: 'Statistics View',
    enabled: 'Enabled',
    disabled: 'Disabled',
    noCampaignsRunning: 'No campaigns running'
};
