import {memo, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import MainLayout from '../MainLayout';
import PageLoader from '../PageLoader';

const ProtectedRoutesLayout = () => {
    return (
        <MainLayout>
            {/* <Suspense fallback={<PageLoader />}> */}
            <Outlet />
            {/* </Suspense> */}
        </MainLayout>
    );
};

export default memo(ProtectedRoutesLayout);
