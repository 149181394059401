// prettier-ignore
export default {
    landingDescription: 'Suivez en temps quasi-réel les performances de vos campagnes et activations prévues dans votre abonnement. Optimisez vos investissements. Prenez le contrôle sur votre budget marketing digital.',
    aFullyFeatured: 'L\'outil de transparence marketing',
    for: 'par',
    yes: 'Oui',
    no: 'Non',
    expired: 'Expiré',
    countries: 'Pays',
    login: 'Connexion',
    logout: 'Déconnexion',
    name: 'Nom',
    lastName: 'Nom de famille',
    description: 'Description',
    invalidEmail: 'Email invalide',
    email: 'Email',
    update: 'Mettre à jour',
    lastUpdatedBy: 'Dernière mise à jour par',
    yourEmail: 'Votre email',
    required: 'Obligatoire',
    password: 'Mot de passe',
    yourPassword: 'Votre mot de passe',
    goBackToLogin: 'Go Back to Login',
    goBackToHomepage: 'Retourner à la page d\'accueil',
    welcomeBack: 'Content de te revoir',
    enterCode: 'Entrez le code',
    twoFactorAuth: 'Authentification à deux facteurs',
    twoFactorAuthInfo: 'Vous pouvez utiliser votre application mobile d\'authentification à deux facteurs ou un code de secours.',
    enterAuthenticationBackupCode: "Entrez le code d'authentification / de sauvegarde",
    enterAuthenticationBackupCodePlaceholder: 'Code d\'authentification à 6 chiffres / code de secours à 8 chiffres',
    enterAuthenticationCode: 'Entrez le code d\'authentification',
    enterAuthenticationCodeInfo: 'Entrez le code de l\'application d\'authentification sur votre appareil mobile',
    backupCodesInfo: 'Si vous perdez votre téléphone ou l\'accès à votre mot de passe secret à usage unique, chacun de ces codes de récupération peut être utilisé une fois pour retrouver l\'accès à votre compte. Veuillez les conserver en lieu sûr.',
    downloadBackupCodes: 'Télécharger les codes de sauvegarde',
    generateBackupCodes: 'Générer des codes de sauvegarde',
    errorComponentTitle: 'Quelque chose a mal tourné...',
    errorComponentDescription: 'Nos serveurs n\'ont pas pu traiter votre demande pour ces données spécifiques. Ne vous ne vous inquiétez pas, notre équipe de développement a déjà été informée. Essayez de rafraîchir la page dans  quelques minutes.',
    comingSoon: 'Bientôt disponible',
    code: 'Code',
    copyCodes: 'Copier les codes',
    copiedCodes: 'Codes copiés',
    downloadCodes: 'Télécharger les codes',
    proceed: 'Procéder',
    secretKeyInfo: 'Si vous ne parvenez pas à scanner le code QR, saisissez manuellement la clé secrète dans votre application',
    authenticationAppInfo: 'Utilisez l\'application d\'authentification mobile pour scanner le code qr',
    scanQrCode: 'Scanner le code QR',
    notAuthenticated: 'Vous n\'êtes pas authentifié',
    youAreNotAuthorized: 'Vous n\'êtes pas autorisé à accéder à cette page',
    error: 'Erreur',
    characterRequiredWithCount: '{{ count }} caractères requis',
    characterRequiredWithCountTwo: '{{ count1 }} ou {{ count2 }} caractères requis',
    nextStep: 'Étape suivante',
    back: 'Retour',
    cancel: 'Annuler',
    users: 'Utilisateurs',
    edit: 'Modifier',
    delete: 'Effacer',
    search: 'Recherche',
    deleteUserInfo: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    businessAccountUserDeleted: 'Utilisateur du compte professionnel supprimé',
    businessAccountUserUpdated: 'Utilisateur du compte professionnel mis à jour',
    businessAccountDeletedWithName: 'L\'utilisateur du compte professionnel "{{ name }}" a été supprimé.',
    clearFilters: 'Effacer les filtres',
    errorNotificationTitle: 'Erreur',
    successNotificationTitle: 'Succès',
    accountActivatedSuccessfully: 'Votre compte a été activé avec succès',
    forbiddenErrorInfo: 'Malheureusement, il ne s\'agit que d\'une page 403. Vous n\'avez pas la permission d\'afficher cette page.',
    takeMeBackToHomePage: 'Retour à la page d\'accueil',
    permissions: 'Autorisations',
    people: 'personnes',
    audienceReachedOut: 'Votre audience cumulée atteint aujourd\'hui',
    subscription: 'Abonnement',
    noCampaignsInfo: 'Pas d\'activation planifiée pour l\'instant! Cela ne saurait tarder, notre équipe y travaille!',
    upcoming: 'Prochainement',
    media: 'Média',
    mediaSpace: 'L\'espace MEDIA',
    mediaSpaceInfo: 'Déposez ici tous les documents, logos ou images nécessaires à vos activations.',
    mediaCreatedSuccessfully: 'Média créé avec succès',
    deleteMediaInfo: "Média supprimé avec succès",
    mediaDeletedSuccessfully: 'Média supprimé avec succès',
    mediaUpdatedSuccessfully: 'Les médias ont été mis à jour avec succès',
    fileTooLargeWithSize: 'Le fichier est trop volumineux. La taille maximale est de {{ size }}',
    fileInvalidType: 'Type de fichier non valide : jpg, jpeg, gif, webp et pdf sont autorisés.',
    sendInvitation: 'Envoyer une invitation',
    invitationSentSuccessfully: 'Invitation sent successfully',
    invite: 'Inviter',
    or: 'ou',
    yourExpert: 'Votre expert',
    weHaveAMessage: 'Vous avez un message:',
    noMessage: 'Pas de message',
    darkTheme: 'Thème sombre',
    lightTheme: 'Thème clair',
    campaigns: 'Campagnes',
    mailings: 'Envois de courriels',
    articles: 'Articles',
    videos: 'Vidéos',
    social: 'Social',
    notifications: 'Notifications',
    survey: 'Sondage',
    activations: 'Activations effectuées',
    planned_activations: 'Activations planifiées',
    contractDuration: 'Durée de l\'abonnement',
    daysRemainingWithCount_one: '{{ count }} jour restant',
    daysRemainingWithCount_other: '{{ count }} jours restants',
    daysPassedWithCount_one: '{{ count }} jour passé',
    daysPassedWithCount_other: '{{ count }} jours passés',
    contractStartsInWithCount_one: 'L\'abonnement commence dans\n{{ count }} jour',
    contractStartsInWithCount_other: 'L\'abonnement commence dans\n{{ count }} jours',
    contractExpiredWithCount_one: 'L\'abonnement a expiré il y a\n{{ count }} jour',
    contractExpiredWithCount_other: 'L\'abonnement a expiré il y a\n{{ count }} jours',
    noContractRunning: 'Aucun abonnement en cours',
    toDo: 'à faire',
    done: 'terminées',
    performance: 'Performance',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    duration: 'Durée',
    published: 'Publié',
    scope: 'Portée',
    views: 'Vues',
    viewsWithCount_one: '{{ count }} vue',
    viewsWithCount_other: '{{ count }} vues',
    dragFiles: 'Glissez vos images ici ou sélectionnez un fichier depuis votre ordinateur',
    dragFilesInfo: 'Vous pouvez télécharger autant de fichiers que vous voulez, chacun ne doit pas excéder 5mb',
    footerBusinessInfo: 'Business Dashboard est une solution de suivi marketing pour les Membres Business qui ont souscrit à l\'option. Ce site et son contenu sont la propriété exclusive de Horse Republic SAS. Tous droits réservés. Version bêta novembre 2022',
    dashboard: 'Tableau de bord',
    uploadMedia: 'Télécharger un média',
    getInTouch: 'Prendre contact',
    myAccounts: 'Mes comptes',
    contracts: 'Contrats',
    bills: 'Factures',
    create: 'Créer',
    created: 'Créé',
    sentAt: 'Envoyé à',
    totalClicks: 'Total de clics',
    totalViews: 'Total de vues',
    readings: 'Lectures',
    crt: 'CRT',
    type: 'Type',
    message: 'Message',
    statsView: 'Vue des statistiques',
    enabled: 'Activé',
    disabled: 'Désactivé',
    noCampaignsRunning: 'Aucune campagne en cours'
};
