import {Suspense, memo} from 'react';
import {useRoutes} from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import publicRoutes from './public';
import protectedRoutes from './protected';
import commonRoutes from './common';

const AppRoutes = () => {
    const element = useRoutes([...publicRoutes, ...protectedRoutes, ...commonRoutes]);

    // return <Suspense fallback={<PageLoader />}>{element}</Suspense>;
    return <Suspense>{element}</Suspense>;
};

export default memo(AppRoutes);
