import useTwoFaStore from 'features/TwoFa/store/useTwoFaStore';

interface HasPermissionPropsType {
    requiredPermission: string | null;
}
interface PermissionsToNavigatePropsType {
    permissions: string[];
}

const globalPermissions = ['admin'];
const permissionsNavigatePaths = {
    can_view_bills: '/bills',
    business_owner: '/dashboard',
    can_view_dashboard: '/dashboard',
    can_view_contracts: '/contracts'
};

const usePermissions = () => {
    const hasPermission = (props: HasPermissionPropsType) => {
        const {currentBusinessAccount} = useTwoFaStore();
        const {requiredPermission = null} = props;

        const hasGlobalPermission = globalPermissions.includes(requiredPermission ?? '');
        const isBusinessOwner = currentBusinessAccount?.permissions?.includes('business_owner');
        const hasBusinessAccountPermission = currentBusinessAccount?.permissions?.includes(
            requiredPermission ?? ''
        );

        return hasGlobalPermission || hasBusinessAccountPermission || isBusinessOwner;
    };

    const permissionToNavigate = (props: PermissionsToNavigatePropsType) => {
        const {permissions} = props;

        if (permissions.length === 0) return '/403';

        for (const [permission, path] of Object.entries(permissionsNavigatePaths))
            if (permissions.includes(permission)) return path;

        return '/403';
    };

    return {
        hasPermission,
        permissionToNavigate
    };
};

export default usePermissions;
