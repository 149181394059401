import {useState, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {UnstyledButton, Menu, Image, Group} from '@mantine/core';
import {ChevronDown} from 'tabler-icons-react';

import {useStyles} from './LanguagePickerStyles';
import english from './images/english.png';
import french from './images/french.png';

type LanguagesType = keyof typeof languages;

const languages = {
    en: {image: english},
    fr: {image: french}
};

const HeaderComponent = () => {
    const {i18n} = useTranslation();
    const [opened, setOpened] = useState(false);
    const {classes} = useStyles({opened});

    const changeLanguageHandler = (language: string) => i18n.changeLanguage(language);
    const currentLanguage = languages[i18n.resolvedLanguage as LanguagesType];

    const languagesDropdown = Object.keys(languages).map((language) => (
        <Menu.Item
            icon={
                <Image
                    src={languages[language as LanguagesType].image}
                    width={24}
                    height={24}
                />
            }
            onClick={() => {
                changeLanguageHandler(language);
                window.location.reload();
            }}
            key={language}
        ></Menu.Item>
    ));

    return (
        <>
            <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="target"
            >
                <Menu.Target>
                    <UnstyledButton className={classes.control}>
                        <Group spacing="xs">
                            <Image
                                src={currentLanguage?.image}
                                width={22}
                                height={22}
                            />
                        </Group>
                        <ChevronDown
                            className={classes.icon}
                            size={16}
                        />
                    </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>{languagesDropdown}</Menu.Dropdown>
            </Menu>
        </>
    );
};

export default memo(HeaderComponent);
