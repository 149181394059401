import {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';

const RequireAuthAndPermission = lazy(() => import('components/RequireAuthAndPermission'));
const BusinessAccounts = lazy(() => import('../views/BusinessAccountUsers'));
const BusinessAccount = lazy(() => import('../views/BusinessAccountUser'));
const BusinessAccountsInvite = lazy(() => import('../views/BusinessAccountUserInvite'));

export default [
    {
        path: 'business-account-users',
        element: (
            <RequireAuthAndPermission requiredPermission="business_account_users">
                <BusinessAccounts />
            </RequireAuthAndPermission>
        )
    },
    {
        path: 'business-account-users/:id',
        element: (
            <RequireAuthAndPermission requiredPermission="business_account_users">
                <BusinessAccount />
            </RequireAuthAndPermission>
        )
    },
    {
        path: 'business-account-users-invite',
        element: (
            <RequireAuthAndPermission requiredPermission="business_account_users">
                <BusinessAccountsInvite />
            </RequireAuthAndPermission>
        )
    }
] as RouteObject[];
