import {memo} from 'react';
import {AppShell, Container} from '@mantine/core';

import Header from '../UI/Header';
import Footer from '../UI/Footer';
import {Props} from './MainLayoutTypes';

const MainLayout = (props: Props) => {
    const {children} = props;

    return (
        <AppShell
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            fixed
            header={<Header />}
            footer={<Footer />}
        >
            <Container size="xl">{children}</Container>
        </AppShell>
    );
};

export default memo(MainLayout);
