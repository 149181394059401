import {memo} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Header, Group, Container, Burger, Paper, Transition, Anchor, Image} from '@mantine/core';
import {useDisclosure, useClickOutside} from '@mantine/hooks';

import usePermissions from 'hooks/usePermissions';
import {useStyles} from './HeaderStyles';
import UserAvatar from '../UserAvatar';
import LanguagePicker from '../LanguagePicker';
import {links} from './HeaderLinks';
import hrLogo from 'images/hr-logo.svg';

const HeaderComponent = () => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const [opened, {close, toggle}] = useDisclosure(false);
    const burgerRef = useClickOutside(() => close());
    const {pathname} = useLocation();
    const {hasPermission} = usePermissions();

    const isForbidden = (requiredPermission: string) => hasPermission({requiredPermission});

    const navigationLinks = links
        .filter((link) => isForbidden(link.permission))
        .map((link) => (
            <Anchor
                component={Link}
                key={link.label}
                to={link.to}
                className={cx(classes.link, {[classes.linkActive]: pathname.includes(link.to)})}
                onClick={() => close()}
            >
                {t(link.label)}
            </Anchor>
        ));

    return (
        <>
            <Header height={56}>
                <Container size="xl">
                    <div className={classes.innerContainer}>
                        <Group
                            spacing={5}
                            className={classes.links}
                        >
                            <Image
                                width={32}
                                src={hrLogo}
                                mr="xl"
                            />
                            {navigationLinks}
                        </Group>

                        <Burger
                            opened={opened}
                            onClick={toggle}
                            className={classes.burger}
                            ref={burgerRef}
                        />

                        <Transition
                            transition="pop-top-right"
                            duration={200}
                            mounted={opened}
                        >
                            {(styles) => (
                                <Paper
                                    className={classes.dropdown}
                                    withBorder
                                    style={styles}
                                >
                                    {navigationLinks}
                                </Paper>
                            )}
                        </Transition>
                        <Group position="right">
                            <LanguagePicker />
                            <UserAvatar />
                        </Group>
                    </div>
                </Container>
            </Header>
        </>
    );
};

export default memo(HeaderComponent);
