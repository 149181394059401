import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Group, ActionIcon, Image, Text} from '@mantine/core';

import {links} from './FooterLinks';
import useStyles from './FooterStyles';
import hrLogo from 'images/hr-logo-with-letters.png';

const Footer = () => {
    const {t} = useTranslation();
    const {classes} = useStyles();

    const socialLinks = links.map((link) => {
        return (
            <ActionIcon
                size="xl"
                onClick={() => window.open(link.url)}
                key={link.url}
            >
                {link.component}
            </ActionIcon>
        );
    });

    return (
        <div className={classes.footer}>
            <Container className={classes.inner}>
                <Group className={classes.companyInfo}>
                    <Image
                        width={96}
                        src={hrLogo}
                    />
                    <Text
                        color="dimmed"
                        className={classes.companyInfoText}
                    >
                        {`${t('footerBusinessInfo')}`}
                    </Text>
                </Group>
                <Group
                    spacing={0}
                    className={classes.links}
                    position="right"
                    noWrap
                >
                    {socialLinks}
                </Group>
            </Container>
        </div>
    );
};

export default memo(Footer);
